// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stylaGallery_caruselSize__7iQSc{
    width: 800px;
}
.stylaGallery_sizingPhoto__78uDy{
    height: 600px;
    width: 800px;
    align-items: center;
    justify-content: center;
    display: flex;
}

@media screen and (max-width : 1024px) {
    .stylaGallery_sizingPhoto__78uDy{
        height: 470px;
        width: 635px;
        align-items: center;
        justify-content: center;
        display: flex;
    }
    .stylaGallery_caruselSize__7iQSc{
        width: 655px;
    }
}
@media screen and (max-width : 800px) {
    .stylaGallery_sizingPhoto__78uDy{
        height: 250px;
        width: 355px;
        align-items: center;
        justify-content: center;
        display: flex;
    }
    .stylaGallery_caruselSize__7iQSc{
        width: 355px;
    }
}
@media screen and (max-width : 420px) {

}`, "",{"version":3,"sources":["webpack://./src/components/gallary/stylaGallery.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI;QACI,aAAa;QACb,YAAY;QACZ,mBAAmB;QACnB,uBAAuB;QACvB,aAAa;IACjB;IACA;QACI,YAAY;IAChB;AACJ;AACA;IACI;QACI,aAAa;QACb,YAAY;QACZ,mBAAmB;QACnB,uBAAuB;QACvB,aAAa;IACjB;IACA;QACI,YAAY;IAChB;AACJ;AACA;;AAEA","sourcesContent":[".caruselSize{\n    width: 800px;\n}\n.sizingPhoto{\n    height: 600px;\n    width: 800px;\n    align-items: center;\n    justify-content: center;\n    display: flex;\n}\n\n@media screen and (max-width : 1024px) {\n    .sizingPhoto{\n        height: 470px;\n        width: 635px;\n        align-items: center;\n        justify-content: center;\n        display: flex;\n    }\n    .caruselSize{\n        width: 655px;\n    }\n}\n@media screen and (max-width : 800px) {\n    .sizingPhoto{\n        height: 250px;\n        width: 355px;\n        align-items: center;\n        justify-content: center;\n        display: flex;\n    }\n    .caruselSize{\n        width: 355px;\n    }\n}\n@media screen and (max-width : 420px) {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"caruselSize": `stylaGallery_caruselSize__7iQSc`,
	"sizingPhoto": `stylaGallery_sizingPhoto__78uDy`
};
export default ___CSS_LOADER_EXPORT___;
