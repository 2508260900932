// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Scheme_schemeBlock__0xPWy {
    margin: 40px auto 0;
    width: 1200px;
    display: flex;
    gap: 30px;
    justify-content: center;
    flex-direction: column;
}

h3 {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 4px;
}
.Scheme_MobileSchemeImg__AWYEs{
    display: none;
}
@media screen and (max-width : 1024px) {
    h3{
        font-size: 28px;
    }
    .Scheme_schemeBlock__0xPWy {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 25px;
        align-items: center;
    }
    .Scheme_schemeImg__F\\+WwP{
        display: none;
    }
    .Scheme_MobileSchemeImg__AWYEs{
        display: block;
        height: 500px;
        width: -moz-fit-content;
        width: fit-content;
        margin-bottom: 20px;
    }
}
@media screen and (max-width : 800px) {
    h3{
        font-size: 25px;
    }
    .Scheme_schemeBlock__0xPWy {
        margin: 50px auto 0;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 15px
    }
    .Scheme_schemeImg__F\\+WwP{
        display: none;
    }
}
@media screen and (max-width : 420px) {

}`, "",{"version":3,"sources":["webpack://./src/components/Scheme/Scheme.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,SAAS;IACT,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,oBAAoB;IACpB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,aAAa;AACjB;AACA;IACI;QACI,eAAe;IACnB;IACA;QACI,WAAW;QACX,aAAa;QACb,uBAAuB;QACvB,sBAAsB;QACtB,SAAS;QACT,mBAAmB;IACvB;IACA;QACI,aAAa;IACjB;IACA;QACI,cAAc;QACd,aAAa;QACb,uBAAkB;QAAlB,kBAAkB;QAClB,mBAAmB;IACvB;AACJ;AACA;IACI;QACI,eAAe;IACnB;IACA;QACI,mBAAmB;QACnB,WAAW;QACX,aAAa;QACb,uBAAuB;QACvB,sBAAsB;QACtB;IACJ;IACA;QACI,aAAa;IACjB;AACJ;AACA;;AAEA","sourcesContent":[".schemeBlock {\n    margin: 40px auto 0;\n    width: 1200px;\n    display: flex;\n    gap: 30px;\n    justify-content: center;\n    flex-direction: column;\n}\n\nh3 {\n    color: #000;\n    text-align: center;\n    font-family: Poppins;\n    font-size: 40px;\n    font-style: normal;\n    font-weight: 900;\n    line-height: normal;\n    letter-spacing: 4px;\n}\n.MobileSchemeImg{\n    display: none;\n}\n@media screen and (max-width : 1024px) {\n    h3{\n        font-size: 28px;\n    }\n    .schemeBlock {\n        width: 100%;\n        display: flex;\n        justify-content: center;\n        flex-direction: column;\n        gap: 25px;\n        align-items: center;\n    }\n    .schemeImg{\n        display: none;\n    }\n    .MobileSchemeImg{\n        display: block;\n        height: 500px;\n        width: fit-content;\n        margin-bottom: 20px;\n    }\n}\n@media screen and (max-width : 800px) {\n    h3{\n        font-size: 25px;\n    }\n    .schemeBlock {\n        margin: 50px auto 0;\n        width: 100%;\n        display: flex;\n        justify-content: center;\n        flex-direction: column;\n        gap: 15px\n    }\n    .schemeImg{\n        display: none;\n    }\n}\n@media screen and (max-width : 420px) {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"schemeBlock": `Scheme_schemeBlock__0xPWy`,
	"MobileSchemeImg": `Scheme_MobileSchemeImg__AWYEs`,
	"schemeImg": `Scheme_schemeImg__F+WwP`
};
export default ___CSS_LOADER_EXPORT___;
