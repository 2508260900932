// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vkButton_vkbtn__zbf3h {
    width: 385px;
    height: 54px;
    flex-shrink: 0;
    border-radius: 16px 0px;
    background: linear-gradient(90deg, #2A5067 0%, #292E49 100%);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    color: white;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    margin: 0;
    line-height: 0;
    letter-spacing: 0;
    padding: 15px 0;
}
.vkButton_logoStyle__9kf8r{
    width: 40px;
    height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/vkButton.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,cAAc;IACd,uBAAuB;IACvB,4DAA4D;IAC5D,gDAAgD;IAChD,aAAa;IACb,YAAY;IACZ,6BAA6B;IAC7B,mBAAmB;IACnB,eAAe;IACf,SAAS;IACT,cAAc;IACd,iBAAiB;IACjB,eAAe;AACnB;AACA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".vkbtn {\n    width: 385px;\n    height: 54px;\n    flex-shrink: 0;\n    border-radius: 16px 0px;\n    background: linear-gradient(90deg, #2A5067 0%, #292E49 100%);\n    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);\n    display: flex;\n    color: white;\n    justify-content: space-around;\n    align-items: center;\n    cursor: pointer;\n    margin: 0;\n    line-height: 0;\n    letter-spacing: 0;\n    padding: 15px 0;\n}\n.logoStyle{\n    width: 40px;\n    height: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vkbtn": `vkButton_vkbtn__zbf3h`,
	"logoStyle": `vkButton_logoStyle__9kf8r`
};
export default ___CSS_LOADER_EXPORT___;
