// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reviews_rewiewsBlock__J9lee{
    display: flex;
    width: 900px;
    margin: 0 auto 60px;
    text-align: center;
    padding: 0 0 0 15rem;
}
.reviews_galaryBlock__1mMtI{
    width: 1200px;
    margin: 0 auto;
}
.reviews_images__Ylz-6{
    width: 600px;
}

@media screen and (max-width : 1024px) {
    
}
@media screen and (max-width : 800px) {
    .reviews_rewiewsBlock__J9lee{
        display: flex;
        width: 100%;
        margin: 0 auto 60px;
        text-align: center;
        padding: 0 0 0 0;
    }
    .reviews_galaryBlock__1mMtI{
        width: 100%;
        margin: 0 auto;
    }
    .reviews_images__Ylz-6{
        width: 100%;
    }
}
@media screen and (max-width : 420px) {

}`, "",{"version":3,"sources":["webpack://./src/components/reviews/reviews.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,oBAAoB;AACxB;AACA;IACI,aAAa;IACb,cAAc;AAClB;AACA;IACI,YAAY;AAChB;;AAEA;;AAEA;AACA;IACI;QACI,aAAa;QACb,WAAW;QACX,mBAAmB;QACnB,kBAAkB;QAClB,gBAAgB;IACpB;IACA;QACI,WAAW;QACX,cAAc;IAClB;IACA;QACI,WAAW;IACf;AACJ;AACA;;AAEA","sourcesContent":[".rewiewsBlock{\n    display: flex;\n    width: 900px;\n    margin: 0 auto 60px;\n    text-align: center;\n    padding: 0 0 0 15rem;\n}\n.galaryBlock{\n    width: 1200px;\n    margin: 0 auto;\n}\n.images{\n    width: 600px;\n}\n\n@media screen and (max-width : 1024px) {\n    \n}\n@media screen and (max-width : 800px) {\n    .rewiewsBlock{\n        display: flex;\n        width: 100%;\n        margin: 0 auto 60px;\n        text-align: center;\n        padding: 0 0 0 0;\n    }\n    .galaryBlock{\n        width: 100%;\n        margin: 0 auto;\n    }\n    .images{\n        width: 100%;\n    }\n}\n@media screen and (max-width : 420px) {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rewiewsBlock": `reviews_rewiewsBlock__J9lee`,
	"galaryBlock": `reviews_galaryBlock__1mMtI`,
	"images": `reviews_images__Ylz-6`
};
export default ___CSS_LOADER_EXPORT___;
